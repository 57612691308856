$body-color: #f7f8fa;
$nav-color: #f4f4f4;
$avaka-red: #D1B4D7;
// $avaka-red: #dc3e4c;
// $avaka-red: #f8172d;
$avaka-red-hover: #ed818a;
$avaka-blue: #8EC7E0;
$avaka-yellow-pending: #fff2003b;
$avaka-yellow: #DADADA;
$avaka-yellow-hover: #bbbcbd;
$avaka-gray: #dadada;
$avaka-gray-hover: #bbbcbd;
$avaka-black: #1e1e1e;
$avaka-black-hover: #424242;
$avaka-green: #52c052;
$avaka-green-hover: rgb(170, 236, 168);

@import "bootstrap/scss/bootstrap.scss";
@import "~@fortawesome/fontawesome-free";
@import "select2";
@import "select2-bootstrap-css";


@import './selfservice/buttons.scss';
@import './selfservice/table.scss';
@import './selfservice/inputs.scss';

.required {
  // &::before {
  abbr {
    text-decoration: none;
    // content: " *";
    color: red;
  }
}

// * {
//   font-synthesis: none !important;
// }

@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file+'.otf');
    // src: url($font-file+'.ttf');
    // src: url($font-file+'.eot');
    // src: url($font-file+'.eot?#iefix') format('embedded-opentype'),
    //      url($font-file+'.woff') format('woff'),
    //      url($font-file+'.ttf') format('truetype'),
    //      url($font-file+'.svg#'+$font-family) format('svg');
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
  }
}


// @include font('Manrope', './selfservice/fonts/Manrope-Medium');
// @include font('Manrope-Bold', '/htdocs/lib/fonts/Manrope-Bold');
// @include font('BebasNeue', './selfservice/fonts/bebas_neue/BebasNeue-Regular');
// @include font('Code', './selfservice/fonts/code/CODELight');
// @include font('Beckman', './selfservice/fonts/beckman/Beckman-FREE');
@include font('Gald', './selfservice/fonts/galderglynn_esquire/galderglynn titling lt');

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.close {
  font-size: 14px;
}

.bubble {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $avaka-blue;
  text-align: center;
  color: black;
  font-size: 12px;
  cursor: pointer;
}

.bubble::after {
  content: attr(title-text);
  position: absolute;
  background-color: black;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  bottom: calc(100% + 5px);
  white-space: wrap;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  display: none;
  transition: opacity 0.3s ease;
  z-index: 1;
  min-width: 300px;
}

.bubble:hover::after {
  display: block;
  left: 50%;
  transform: translateX(-50%);
  right: auto;
  margin-left: -150%;
}


.question-mark {
  position: relative;
  z-index: 1;
}

body {
  font-family: 'Gald', 'arial', 'sans-serif';
  color: black;
  font-size: 14px;
}

.dropdown-menu {
  right: 0;
  left: unset;
  min-width: max-content;
}

.apexcharts-tooltip {
  border-radius: 0 !important;
  background: white !important;
  border: 1px solid black !important;
  margin: 0 !important;
  box-shadow: none !important;
}

.apexcharts-tooltip-title {
  background: white !important;
  border-bottom: 1px solid black !important;
  margin: 0 !important;
  font-size: 16px !important;
  padding: 6px 10px !important;
}

.apexcharts-tooltip-text {
  font-size: 14px !important;
}

.apexcharts-tooltip-marker {
  width: 8px !important;
  height: 8px !important;
  margin-right: 6px !important;
}

.selfservice .dropdown-menu {
  border-radius: 0;
  border: 0;
  box-shadow: 0px 0px 0px 1px black;
  margin: 0;
  color: black;
}

.selfservice .alert {
  border-radius: 0;
  margin-bottom: 0;
  border: 0;

  &-success {
    color: black;
    background-color: #8EC7E0;
    // background-color: #34B083;
  }

  .close:hover {
    cursor: pointer;
  }
}

.selfservice #flashes {
  .close {
    text-shadow: none;
  }
  
  .alert {
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
  }
}

.selfservice #login-flashes {
  .alert {
    border: 1px solid red;
    color: red;
  }
}

.breadcrumb {
  height: auto !important;
  width: 100% !important;
}

nav:has(.breadcrumb) {
  height: auto !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-bottom: 10px;
}

// .dashboard #cardCollapseForm.show, .dashboard #cardCollapsePayouts.show {
//   min-height: 20rem;
//   // max-height: 20rem;
// }

// .dashboard #cardCollapsePrograms.show, .dashboard #cardCollapseNews.show {
//   min-height: 24rem;
//   // max-height: 24rem;
// }

.short-name {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.shorter-name {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.select2-dropdown {
  z-index:99999;
}

.selfservice nav {
  padding: 0;
  margin-top: 32px;

  .navbar-container {
    border-left: 1px solid $avaka-black;
    border-right: 1px solid black;
    border-bottom: 1px solid $avaka-black;
  }
  
  .nav-link {
    color: black !important;
    transition: text-shadow 0.1s ease;
  }
}

.selfservice nav * {
  height: 100%;
}

.selfservice nav a:hover, .selfservice nav a.active {
  font-weight: 400 !important;
  text-shadow: 0.25px 0 0 currentColor;
  text-decoration: underline;
}

th {
  font-weight: 400 !important;
  text-shadow: 0.25px 0 0 currentColor;
}

strong {
  font-weight: 400 !important;
  text-shadow: 0.25px 0 0 currentColor;
}

.navbar-collapse > .navbar-nav > li, .navbar-collapse > .navbar-nav > ul {
  border-right: 1px solid $avaka-black;
}

.selfservice-container {
  padding-left: 10vw;
  padding-right: 10vw;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.mh-200 {
  max-height: 200px;
}

.mh-100px {
  max-height: 100px;
}

.mh-50px {
  max-height: 50px;
}

.selfservice .table-sortable th:hover {
  cursor: pointer;
}

.selfservice {
  .modal-content {
    border-radius: 0;
  }

  .modal-header {
    border-bottom: 1px solid $avaka-black;
    background-color: white;
  }

  .modal-body {
    background-color: white;
  }

  .modal-footer {
    border-top: 1px solid $avaka-black;
    background-color: white;
  }

  .field-error-area {
    input, select, .select2-selection.select2-selection--multiple, .select2-selection.select2-selection--single {
      border: 1px solid red;
    }
  }

  .field-error {
    color: red;
  }

  .pagination-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  //   float: right;
  //   padding-left: 0;
  //   margin: 20px 0;
  //   border-radius: 4px;
  }

  ul.pagination { 
    margin: 0;
  }
  
  .pagination li {
    display: inline;
  }
  
  a {
    // outline: none !important;
    box-shadow: none !important;
  }

  .pagination>li:first-child>a, .pagination>li:first-child>span {
    margin-left: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  
  .pagination>li:first-child>a, .pagination>li:first-child>span {
    margin-left: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  
  .pagination>.active>a, .pagination>.active>a:hover, .pagination>.active>a:focus, .pagination>.active>span, .pagination>.active>span:hover, .pagination>.active>span:focus {
    z-index: 2;
    color: #000;
    background-color: $avaka-yellow;
    border-color: $avaka-yellow-hover;
    cursor: default;
  }

  .pagination>li>a, .pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.428571429;
    text-decoration: none;
    color: #000;
    background-color: white;
    border: 1px solid $avaka-gray;
    margin-left: -1px;
  }
  
  .pagination>li:last-child>a, .pagination>li:last-child>span {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
  
  .pagination>li>a, .pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.428571429;
    text-decoration: none;
    color: #000;
    background-color: white;
    border: 1px solid $avaka-gray;
    margin-left: -1px;
  }
  
  .pagination>li>a:hover, .pagination>li>span:hover {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.428571429;
    text-decoration: none;
    color: #000;
    background-color: $avaka-yellow-hover;
    border: 1px solid $avaka-gray;
    margin-left: -1px;
  }
}

.progress {
  height: 1.5rem;
  position: fixed;
  top: 10px;
  left: 5%;
  width: 90%;
  margin: auto;
  z-index: 999;
  .progress-divider {
    width: 1px;
  }
}
 
// MENU


.menu-container {
  position: relative;
  display: flex;
  justify-content: end;
}

.overflow-x-auto {
  overflow-x: clip;
}

.menu-toggle {
  cursor: pointer;
  font-size: 1.5em;
  color: #333;
  display: inline-block;
}

.menu-buttons {
  border: 1px solid black;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background: #fff;
  position: absolute;
  z-index: 99999;
  display: none;
  bottom: 30px;
  white-space: nowrap;
}

.menu-buttons a {
  display: block;
  margin-bottom: 5px;
}

.child-row-style {
  padding: 0.75rem 0 !important;
  background-color: white;
}

.subtable td {
  border: none !important;
}

.subtable {
  margin-bottom: 0 !important;
}

.bordered-child {
  // border-top: 1px solid black;
}

.subtable-toggle {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}