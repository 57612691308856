.selfservice {
  input.form-control {
    color: black;
    height: min-content;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid black;

    &:focus {
      box-shadow: none;
      outline: none;
      border: 1px solid black;
    }
  }
  
  select.form-control {
    color: black;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active{
      -webkit-background-clip: text;
      -webkit-text-fill-color: black;
      box-shadow: none;
  }

  input::-webkit-input-placeholder,
  input::-webkit-input-placeholder:hover, 
  input::-webkit-input-placeholder:focus, 
  input::-webkit-input-placeholder:active{
      -webkit-background-clip: text;
      -webkit-text-fill-color: rgb(132, 132, 132);
      box-shadow: none;
  }

  select.form-control {
    height: 100%;
    // height: min-content;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid black;
    // -webkit-text-fill-color: black;
    // color: black;
    
    &:focus {
      box-shadow: none;
      outline: none;
      border: 1px solid black;
    }
  }

  .form-yellow {
    .form-control {
      padding: 0;
      padding-left: 5px;
    }
    // select {
    //   -webkit-text-fill-color: rgb(132, 132, 132);
    //   color: #9e9e9e;
    // }
    // option:not(:first-of-type), select div {
    //   -webkit-text-fill-color: black !important;
    //   color: black !important;
    // }

    input:not(:disabled).form-control, textarea.form-control {
      // height: min-content;
      height: 100%;
      color: black;
      background-color: $avaka-yellow;
      border: 0;
      border-radius: 0;
      border-bottom: 1px solid black;
  
      &:focus {
        box-shadow: none;
        outline: none;
        border: 1px solid black;
      }
    }
    select.form-control {
      height: 100%;
      // height: min-content;
      background-color: $avaka-yellow;
      border: 0;
      border-radius: 0;
      border-bottom: 1px solid black;
      // -webkit-text-fill-color: black;
      // color: black;
      
      &:focus {
        box-shadow: none;
        outline: none;
        border: 1px solid black;
      }
    }

    input:disabled {
      background-color: $avaka-yellow;
    }

    .input-group-append span {
      background-color: $avaka-yellow;
      color: black;
    }

    .select2-selection__choice__remove {
      border-right: none;
      color: black;
    }

    .select2-selection__choice__remove:hover {
      background-color: transparent;
    }


    .select2-selection__choice {
      // background-color: #dbd000 !important;
      // border: none;
      // color: black;
      border-radius: 1em;
      font-size: 12px;
      padding: 2px 5px;
      margin: 2px;
      background-color: white;
      color: $avaka-black;
      border: 1px solid black;
      display: inline;
      align-items: center;

      .select2-selection__choice__display {
        margin-left: 5px;
      }
    }



//*[@id="programs-search"]/select[2]//div
    // option[value=""]{
    //   -webkit-text-fill-color: rgb(132, 132, 132);
    //   color: rgb(132, 132, 132);  
    // }
    
    // option{
    //   -webkit-text-fill-color: black;
    //   color: black;
    // }
  }

  .input-group {
    align-items: center;
  }

  .input-group-append {
    align-items: center;
  }

  .select2-container {
    min-height: 40px;
  }

  .select2-selection {
    min-height: 40px;
    padding-left: 5px;
    padding-right: 12px;
    padding-top: 4px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid black;
  }

  .select2-selection__placeholder {
    font-size: 10px;
  }

  .border-danger {
    .select2-selection {
      border-bottom: 1px solid red !important;
    }
  }

  .form-yellow {
    .select2-selection {
      background-color: $avaka-yellow;
    }
  
    .select2-search__field {
      color: black;
      background-color: $avaka-yellow;
      text-transform: uppercase;
      font-family: 'gald', 'arial', 'sans-serif' !important;
      margin-top: 0;
      line-height: normal;
      border-radius: 0 !important;
    }
  
    .select2-search {
      background-color: $avaka-yellow;
      border-radius: 0 !important;
    }
  }

  .select2-selection__rendered {
    padding: 0px !important;
    color: black !important;
    text-overflow: clip !important;
  }

  .yellow-select {
    background-color: $avaka-yellow;
  }

  .select2-dropdown{
    border-color: black;
    border-radius: 0;
    padding: 4px;

    .select2-search__field {
      border-color: black;
      border-radius: 0 !important;
      outline-offset: 0;
      color: black;
    }

    .select2-search__field:focus-visible {
      border-radius: 0 !important;
      outline-style: solid;
    }
  }

  #program-members-container {
    .select2-selection--multiple {
      .select2-search--inline {
        height: 20px !important;

        .select2-search__field {
          height: 20px !important;
          border-radius: 0 !important;
        }

      }
    }
  }


  input:not([type="checkbox"]):not([type="radio"]):not([type="file"]) {
    height: 40px !important;
  }

  input[type=checkbox] {
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 0 !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
  select {
    height: 40px !important;
  }

  #basic-addon2 {
    height: 40px !important;
    width: 37.55px !important;
    border: 1px solid black !important;
    border-radius: 0 !important;
  }

  .potential_ownership_form {
    select {
      padding: 5px 6px !important;
    }

    .percentage {
      border-right: 1px solid black !important;
    }

    .country-percentage {
      border-right: 1px solid black !important;
    }

    // .nested-fields {
    //   a {
    //     padding: 9px 16px !important;
    //   }
    // }
    // .add_fields {
    //   padding: 9px 48px !important;
    // }
  }

  #invoices-chart .apexcharts-tooltip {
    color: black !important;
  }

  label {
    margin: 0 !important;
  }
}