
.selfservice {
  .apexcharts-legend-marker {
    vertical-align: middle;    
  }

  .table-data {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  #programs-table_wrapper {
    position: relative;
  }

  #programs-table_length {
    display: flex;
    align-items: center;

    label { 
      margin: 0;
    }
  }

  // Swapped by Rytis 2025-01-15
  // #programs-table_processing {
  .dataTables_processing {
    z-index: 1000;
    position: absolute;
    top: 25%;
    left: 25%;
    width: 50%;
    padding: 1rem;
    text-align: center;
    box-shadow: rgb(130, 130, 130) 0px 0px 15px 0px;
  }

  #ownerships-table_wrapper {
    position: relative;
  }

  #ownerships-table_processing {
    z-index: 1;
    position: absolute;
    top: 5%;
    left: 25%;
    width: 50%;
    padding: 1rem;
    text-align: center;
    box-shadow: rgb(130, 130, 130) 0px 0px 15px 0px;
  }

  #archived-ownerships-table_wrapper {
    position: relative;
  }

  #archived-ownerships-table_processing {
    z-index: 1;
    position: absolute;
    top: 5%;
    left: 25%;
    width: 50%;
    padding: 1rem;
    text-align: center;
    box-shadow: rgb(130, 130, 130) 0px 0px 15px 0px;
  }

  .bg-row {
    &-pending {
      background-color: $avaka-yellow-pending;
    }
    &-cancelled {
      // background-color: $avaka-red;
      background-color: #ffb1b9;
    }
    &-confirmed {
      // background-color: $avaka-green-hover;
      background-color: #c7ffd2;
    }
  }
}

.potential-ownerships-table {
  border-color: black !important;
}

.potential-ownerships-table td {
  border-color: black !important;
}

.potential-ownerships-table tbody {
  border-color: black !important;
  border-width: 1px !important;
}
.selfservice table {
  color: black;
  border-color: black;
  table-layout: fixed;
  width: 100%;

  th {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    vertical-align: middle;
    &.col-1 {
      width: 8.333333% !important;
    }
    &.col-2 {
      width: 16.666667% !important;
    }
    &.col-3 {
      width: 25% !important;
    }
    &.col-4 {
      width: 33.333333% !important;
    }
    &.col-5 {
      width: 41.666667% !important;
    }
    &.col-6 {
      width: 50% !important;
    }
  }

  td {
    border-top: 1px solid black;
    vertical-align: middle;
  }
}
