// Background
.bg-avaka {
    // static
    &-body {
        background-color: $body-color;
        border: none;
    }
    &-red {
        border: none;
        background-color: $avaka-red;
    }
    &-blue {
        border: none;
        background-color: $avaka-blue;
    }
    &-yellow {
        border: none;
        background-color: $avaka-yellow;
    }
    &-gray {
        border: none;
        background-color: $avaka-gray;
    }
    &-black {
        border: none;
        background-color: $avaka-black;
    }
    &-green {
        border: none;
        background-color: $avaka-green;
    }
    // hover
    // &-red:hover {
    //     border: none;
    //     background-color: $avaka-red-hover;
    // }
    // &-yellow:hover {
    //     border: none;
    //     background-color: $avaka-yellow-hover;
    // }
    // &-gray:hover {
    //     border: none;
    //     background-color: $avaka-gray-hover;
    // }
    // &-black:hover {
    //     border: none;
    //     background-color: $avaka-black-hover;
    // }
    // &-green:hover {
    //     border: none;
    //     background-color: $avaka-green-hover;
    // }
}

// Text
.text-avaka {
  // static
  &-red {
    color: $avaka-red;
  }
  &-yellow {
    color: $avaka-yellow;

    &:hover {
      color: $avaka-yellow !important;
    }
  }
  &-gray {
    color: $avaka-gray;
  }
  &-black {

    color: $avaka-black;
    &:hover {
      color: $avaka-yellow !important;
    }
  }
  &-green {
    color: $avaka-green;
  }
}

// links
a.link-avaka {
    color: $avaka-black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    display: flex;
    // static

    &.link {
      display: block;
    }
    
    &.transparent {
      // border: 1px solid $avaka-black;
      outline: 1px solid $avaka-black;
      border-radius: 0px;
      color: $avaka-black;
      background-color: #fff;
      padding: 10.5px 48px;
      max-height: 40px;
      text-align: center;

      &.active {
        text-decoration: underline !important;
        background-color: $avaka-yellow;
      }

    }
    &.blue {
      display: flex;
      align-items: center;
      justify-content: center;
      // border: 1px solid $avaka-black;
      outline: 1px solid $avaka-black;
      border-radius: 0px;
      color: $avaka-black;
      background-color: $avaka-blue;
      padding: 10.5px 48px;
      max-height: 40px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &.red {
      display: flex;
      align-items: center;
      justify-content: center;
      // border: 1px solid $avaka-black;
      outline: 1px solid $avaka-black;

      border-radius: 0px;
      color: $avaka-black;
      background-color: $avaka-red;
      padding: 10.5px 48px;
      max-height: 40px;
      text-align: center;
    }

    &.yellow {
        // border: 1px solid $avaka-black;
        outline: 1px solid $avaka-black;
        border-radius: 0px;
        color: $avaka-black;
        background-color: $avaka-yellow;
        padding: 10.5px 48px;
        max-height: 40px;
        text-align: center;
    }
    &.gray {
        border: none;
        color: $avaka-gray;
    }
    &.black {
        border: none;
        color: $avaka-black;
    }
    &.green {
        // border: 1px solid $avaka-black;
        outline: 1px solid $avaka-black;
        color: $avaka-black;
        border-radius: 0px;
        background-color: $avaka-green;
        padding: 10.5px 48px;
        max-height: 40px;
        text-align: center;
    }
    // hover
    &.transparent:hover {
        // border: 1px solid $avaka-black;
        outline: 1px solid $avaka-black;
        border-radius: 0px;
        color: $avaka-black;
        background-color: $avaka-yellow;
        padding: 10.5px 48px;
        max-height: 40px;
    }
    &.yellow:hover {
        // border: 1px solid $avaka-black;
        outline: 1px solid $avaka-black;
        border-radius: 0px;
        color: $avaka-black;
        background-color: #fff;
        padding: 10.5px 48px;
        max-height: 40px;
    }
    &.gray:hover {
        // border: none;
        outline: none;
        text-decoration: none;
        color: $avaka-gray-hover;
    }
    &.black:hover {
        // border: none;
        outline: none;
        text-decoration: none;
        color: $avaka-black-hover;
        box-shadow: 0px 2px 0px 0px $avaka-yellow !important;
    }
    &.green:hover {
        // border: 1px solid $avaka-black;
        outline: 1px solid $avaka-black;
        border-radius: 0px;
        color: $avaka-black;
        padding: 10.5px 48px;
        max-height: 40px;
    }
}

.dropdown-toggle:hover {
  text-decoration: none !important;
}

.button-avaka {
  border-radius: 0;
  color: $avaka-black;
  border: none;
  border-collapse: collapse;
  outline: 1px solid $avaka-black;
  padding: 10.5px 16px;
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;

  &.red {
    background-color: $avaka-red;
    &:hover {
      color: black;
      // outline: none;
      text-decoration: underline;
    }
  }

  &.yellow {
    background-color: $avaka-yellow;
    &:hover {
      color: $avaka-black;
      background-color: #fff;
    }
  }

  &.white {
    background-color: #fff;
    &:hover {
      text-decoration: underline;
      background-color: $avaka-yellow;
    }
  }
}

// nav links
.menu-nav-link {
  color: $avaka-black;
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0.5rem;
  border: none;

  &:first-child:hover {
    border-top: none;
    border-bottom: 1px solid $avaka-black;
    border-radius: 0px;
    color: $avaka-black;
    background-color: $avaka-yellow;
  }
  &:last-child:hover {
    border-bottom: none;
    border-top: 1px solid $avaka-black;
    border-radius: 0px;
    color: $avaka-black;
    background-color: $avaka-yellow;
  }
  &:hover {
    border-top: 1px solid $avaka-black;
    border-bottom: 1px solid $avaka-black;
    border-radius: 0px;
    color: $avaka-black;
    background-color: $avaka-yellow;
  }
}

// buttons
// custom
.icon-button-avaka {
    // static
    &-red {
        padding: 4px 8px;
        border-radius: 2px;
        border: none;
        color: $avaka-black;
        background-color: $avaka-red;

        svg {
            height: 14px;
            width: 14px;
        }
    }
    &-red-light {
        padding: 4px 8px;
        border-radius: 2px;
        border: none;
        color: $avaka-black;
        background-color: $avaka-red-hover;

        svg {
            height: 14px;
            width: 14px;
        }
    }
    &-yellow {
        padding: 4px 8px;
        border-radius: 2px;
        border: none;
        color: $avaka-black;
        background-color: $avaka-yellow;

        svg {
            height: 14px;
            width: 14px;
        }
    }
    &-gray {
        padding: 4px 8px;
        border-radius: 2px;
        border: none;
        color: $avaka-black;
        background-color: $avaka-gray;

        svg {
            height: 14px;
            width: 14px;
        }
    }
    &-black {
        padding: 4px 8px;
        border-radius: 2px;
        border: none;
        color: $avaka-yellow;
        background-color: $avaka-black;

        svg {
            height: 14px;
            width: 14px;
        }
    }
    &-green {
        padding: 4px 8px;
        border-radius: 2px;
        border: none;
        color: $avaka-black;
        background-color: $avaka-green;

        svg {
            height: 14px;
            width: 14px;
        }
    }

    // hover
    &-red:hover {
        border: none;
        text-decoration: none;
        color: $avaka-black-hover;
        background-color: $avaka-red-hover;
    }
    &-yellow:hover {
        border: none;
        text-decoration: none;
        color: $avaka-black-hover;
        background-color: $avaka-yellow-hover;
    }
    &-gray:hover {
        border: none;
        text-decoration: none;
        color: $avaka-black-hover;
        background-color: $avaka-gray-hover;
    }
    &-black:hover {
        border: none;
        text-decoration: none;
        color: $avaka-yellow-hover;
        background-color: $avaka-black-hover;
    }
    &-green:hover {
        border: none;
        text-decoration: none;
        color: $avaka-black-hover;
        background-color: $avaka-green-hover;
    }
}

.list-group {
  .list-group-item {
    border-radius: 0;

    &:hover {
      background-color: $avaka-yellow;
    }
  }
}

// pills and badges ;)
.selfservice {
    .avaka-badge {
        &-yellow {
            border-radius: 1em;
            padding: 4px 10px;
            margin: 2px;
            background-color: $avaka-yellow;
            color: $avaka-black;
            border: 1px solid black;
        }
        &-black {
            border-radius: 1em;
            padding: 4px 10px;
            margin: 2px;
            background-color: $avaka-black;
            color: white;
            border: 1px solid black;
        }
        &-transparent {
            border-radius: 1em;
            padding: 4px 10px;
            margin: 2px;
            background-color: white;
            color: $avaka-black;
            border: 1px solid black;
        }
        &-green {
            border-radius: 1em;
            padding: 4px 10px;
            margin: 2px;
            background-color: $avaka-green;
            color: $avaka-black;
            border: 1px solid black;
        }
        &-red {
            border-radius: 1em;
            padding: 4px 10px;
            margin: 2px;
            background-color: $avaka-red;
            color: $avaka-black;
            border: 1px solid black;
        }
    }
}

.avaka-active {
    color: $avaka-black;
    background-color: $avaka-yellow-hover;
}

.collapse-button[aria-expanded=true] .fa-angle-up{
    transform: rotate(-180deg);
}

.fa-angle-up {
    transition: 0.6s;
}

.pagination {
  border-radius: 0 !important;

  .paginate_button {
    border-radius: 0 !important;
    
    a, .page-link {
      border-radius: 0 !important;
    }

    .page-link {
      border-color: black !important;
    }
  }
}